import React, { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { useAuth0 } from "@auth0/auth0-react";

export interface FinndoffUser {
  id?: number;
  active?: boolean;
  externalUserId: string;
  orgNummer?: number;
  hjemmeside?: string;
  firmaNavn: string;
  adresselinje1: string;
  adresselinje2: string;
  postNummer?: string;
  postSted: string;
  kontaktperson: string;
  kontaktpersonMobil: string;
  epostadresse: string;
  nyhetsbrev: boolean;
  paymentMethod?: string;
  productId?: number;
  selectedProduct?: Product;
  paymentPeriodExpiryDate?: string;
  trialEndDate?: string;
}

export interface Product {
  productName: string;
  yearlyPrice: string;
}
interface Auth0User {
  picture: string;
  name: string;
  email: string;
}

const userFromAuth0user = (auth0User: Auth0User): FinndoffUser => ({
  kontaktperson: auth0User.name,
  epostadresse: auth0User.email,
  externalUserId: "",
  orgNummer: undefined,
  hjemmeside: "",
  firmaNavn: "",
  adresselinje1: "",
  adresselinje2: "",
  postNummer: "",
  postSted: "",
  kontaktpersonMobil: "",
  nyhetsbrev: false,
  active: false,
  paymentMethod: "",
});

export const isAuthenticated = (user?: FinndoffUser): Boolean => {
  return !!user;
};

export const isRegistered = (user?: FinndoffUser): Boolean => {
  return !!user && !!user.orgNummer && !!user.epostadresse && !!user.kontaktpersonMobil;
};

export const canSaveSearch = (user?: FinndoffUser): Boolean => {
  return !!user;
};

export const UserContext = createContext<{
  finndoffUser?: FinndoffUser;
  setFinndoffUser: (user: FinndoffUser) => void;
  isLoading: boolean;
  hasProcurementPlanner:boolean;
  hasCompanyFollower:boolean;
}>({
  setFinndoffUser: () => {},
  isLoading: true,
  hasProcurementPlanner:false,
  hasCompanyFollower:false,
});

export const useUserContext = () => {
  return useContext(UserContext);
};

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = (props: UserProviderProps): JSX.Element => {
  const [finndoffUser, setFinndoffUser] = useState<FinndoffUser | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { user: auth0User, isAuthenticated } = useAuth0();
  const { response: getResponse, get: getUser } = useApi("/api/me/bruker");
  const { response: postResponse, post: postUser } = useApi("/api/me/bruker");
  const [hasProcurementPlanner, setHasProcurementPlanner] = useState(false);
  const [hasCompanyFollower, setHasCompanyFollower] = useState(false);

  const tryCreateUser = async (auth0User: Auth0User): Promise<FinndoffUser> => {
    const newUserData = userFromAuth0user(auth0User);
    const newUser = await postUser(newUserData);
    if (postResponse.ok) {
      return newUser;
    } else throw new Error("Create user failed");
  };

  const tryGetOrCreateUser = async (auth0User: Auth0User): Promise<FinndoffUser> => {
    const user = await getUser();
    if (getResponse.ok) return user;

    if (getResponse.status === 404) {
      return tryCreateUser(auth0User);
    } else throw new Error("Get user failed for other than 404 reason");
  };

  const setFunctionalityAccess = async (user: FinndoffUser) => {
    if (user.selectedProduct?.productName === "PREMIUM") {
      setHasProcurementPlanner(true);
      setHasCompanyFollower(true);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      tryGetOrCreateUser(auth0User as Auth0User)
        .then((user) => {
          setFinndoffUser(user);
          setFunctionalityAccess(user);
          setIsLoading(false);
        })
        .catch(console.error);
    } else {
      setFinndoffUser(undefined);
      setIsLoading(false);
    }
  }, [isAuthenticated, auth0User]);

  return (
    <UserContext.Provider
      value={{
        finndoffUser,
        setFinndoffUser,
        isLoading,
        hasProcurementPlanner,
        hasCompanyFollower
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
