import React, { FunctionComponent, useEffect, useState } from "react";
import backgroundImage1 from "../../theme/background.jpg";
import backgroundImage2 from "../../theme/bilde_landingsside.jpg";
import backgroundImage3 from "../../theme/nordnorge.jpg";
import backgroundImage4 from "../../theme/arkitektbedriftenebg.jpg";
import backgroundImage5 from "../../theme/elektroinst.jpg";
import backgroundImage6 from "../../theme/mefsorost.jpg";
import backgroundImage7 from "../../theme/byggeborsenfauske2024.jpg";
import backgroundImage8 from "../../theme/byggmesterforbundetbg.png";
import backgroundImage9 from "../../theme/nesobg.png";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { useUserContext } from "../../utils/contexts/UserContext";
import { SignupSideContent } from "./SignupSideContent";
import { useLocation } from "react-router-dom";
import { paths } from "../../app/paths";
import { SearchForm } from "./SearchForm";
import { SignupResult } from "./SignupResult";
import { Label } from "@mui/icons-material";
import { SignupFormBransje } from "./SignupFormBransje";
import { useNavigate } from "react-router-dom";

export const SignupBransje: FunctionComponent = () => {
  const { isLoading } = useUserContext();
  const [emailSent, setEmailSent] = useState(false);
  const [searchFormTrigger, setSearchFormTrigger] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(backgroundImage1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("_referral") ? params.get("_referral")?.toLowerCase() : "";
  const [externalUserId, setExternalUserId] = useState("");
  const [industryCode, setIndustryCode] = useState("");
  const navigate = useNavigate();

  const [inviteError, setInviteError] = useState(false);

  useEffect(() => {
    let invalidReferral = false;
    switch (referralCode) {
      case "mefmember":
        setBackgroundImage(backgroundImage2);
        break;
      case "mefsorost":
        setBackgroundImage(backgroundImage2);
        break;
      case "mefvest":
        setBackgroundImage(backgroundImage2);
        break;
      case "mefmidt":
        setBackgroundImage(backgroundImage2);
        break;
      case "mefsorvest":
        setBackgroundImage(backgroundImage2);
        break;
      case "mefost":
          setBackgroundImage(backgroundImage2);
          break;
      case "clickedtitle":
        setBackgroundImage(backgroundImage3);
        break;
      case "arkitektbedriftene":
        setBackgroundImage(backgroundImage4);
        break;
      case "elektroinst":
        setBackgroundImage(backgroundImage5);
        break;
      case "byggeborsenfauske2024":
        setBackgroundImage(backgroundImage7);
        break;
      case "byggmesterforbundet":
          setBackgroundImage(backgroundImage8);
          break;
      case "neso":
        setBackgroundImage(backgroundImage9);
        break;
      case "entreprenor":
          setBackgroundImage(backgroundImage2);
          break;
      case "tendpro":
          setBackgroundImage(backgroundImage1);
          break;
      default:
        invalidReferral = true;
        setBackgroundImage(backgroundImage1);
    }

    // Check if referralCode is set and redirect if invalid
    if (referralCode && invalidReferral) {
      navigate(`${paths.signup}`);
    }
  }, [referralCode]);

  useEffect(() => {
    let invalidReferral = false;
    switch (referralCode) {
      case "mefmember":
      case "mefsorost":
      case "mefvest":
      case "mefmidt":
      case "mefost":
      case "mefsorvest":
      case "clickedtitle":
      case "arkitektbedriftene":
      case "elektroinst":
      case "byggeborsenfauske2024":
      case "byggmesterforbundet":
      case "neso":
        invalidReferral = false;
        break;
      default:
        invalidReferral = true;
        break;
    }

    // Check if referralCode is set and redirect if necessary
    if (referralCode && !invalidReferral) {
      const newSearchParams = new URLSearchParams();
        newSearchParams.append("_referral", referralCode);

      navigate(`${paths.industrysignup}?${newSearchParams.toString()}`);
      console.log(`${paths.industrysignup}?${newSearchParams.toString()}`);
    }

  }, [referralCode]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          color: "white",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <Paper
          sx={{
            margin: (theme) => theme.spacing(1),
            display: "flex",
            flexDirection: "row",
            minHeight: "400px",
            minWidth: "400px",
            maxWidth: "800px",
            justifyContent: "space-around",
          }}
        >
          {emailSent && !searchFormTrigger && (
            <Box width="50%" textAlign="center" marginTop="17%">
              <Typography variant="h5">Takk for at du registrerte deg hos Finndoff!</Typography>
              <Typography variant="h6">
                Sjekk innboksen din, og fullfør registreringen fra epost sendt til din epostadresse.
              </Typography>
              <Box marginTop="25px">
                <Link href={paths.start} sx={{ textDecoration: "none" }}>
                  Tilbake til forsiden
                </Link>
              </Box>
            </Box>
          )}
          {!emailSent && inviteError &&  (
            <Box paddingTop="45%" width="100%" textAlign="center">
              <Typography variant="h5">Noe gikk galt med registreringen!</Typography>
              <Link
                href="https://share-eu1.hsforms.com/183V6W1IGRcikwgsn9G8Nngfai8j"
                target="_blank"
                rel="noreferrer"
                sx={{
                  fontSize: "1.2rem",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                  lineHeight: "1.6",
                  letterSpacing: "0.0075em",
                  position: "relative",
                  color: "#008489",
                  marginBottom: "24px",
                  marginTop: "24px",
                  textAlign: "center",
                }}
              >
                Kontakt oss her så hjelper vi deg!
              </Link>
            </Box>
          )}
          {!emailSent && !inviteError &&  (
            <Grid container direction="row">
              <Grid item xl={6} lg={6} md={6} sm={6}>
                <SignupSideContent referralCode={referralCode} />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6}>
                <SignupFormBransje emailSent={setEmailSent} inviteError={setInviteError} searchesFormTrigger={setSearchFormTrigger} externalUserId={setExternalUserId} industryCode={setIndustryCode}/>
              </Grid>
            </Grid>
          )}
          {/* {emailSent && searchFormTrigger &&  (
            <div style={{width: '800px'}}>
              <SearchForm searchesFormTrigger={setSearchFormTrigger} inviteError={setInviteError} externalUserId={externalUserId} industryCode={industryCode}/>
            </div>
          )} */}
        </Paper>
      </Box>
    </>
  );
};
