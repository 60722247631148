import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Backdrop, Typography, Container, Box } from '@mui/material';
import { useApi } from '../../../utils/hooks/useApi';
import { SupplierBulletin, SupplierBulletinCreate } from './datatypes';
import { AnbudSummary, AnbudSummaryCpvCode, AnbudSummaryPlace } from '../Search/datatypes';
import { paths } from "../../../app/paths";

const CreateSupplierBulletin: React.FC = () => {
    const { doffinAnbudId } = useParams<{ doffinAnbudId: string }>();
    const [tender, setTender] = useState<AnbudSummary | null>(null);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [serverError, setServerError] = useState<boolean>(false);
    const [alreadyExists, setAlreadyExists] = useState<boolean>(false);

    const { get: getTender, error: tenderError, loading: tenderLoading } = useApi(`/api/tender/${doffinAnbudId}`);
    const { get: checkBulletin, error: bulletinError, loading: bulletinLoading } = useApi(`/api/bulletin/byDoffinAnbudId/${doffinAnbudId}`);
    const { post: createBulletin, error: createError, loading: createLoading } = useApi('/api/bulletin');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTenderAndCheckBulletin = async () => {
            console.log('Checking existing bulletin...');
            const bulletinResult = await checkBulletin();
            console.log('Bulletin result:', bulletinResult);
            if (bulletinResult && bulletinResult.id) {
                setAlreadyExists(true);
                setLoading(false);
                return;
            }

            const tenderResult = await getTender();
            if (tenderResult?.status === 500) {
                setServerError(true);
            } else if (tenderResult?.status === 404 || !tenderResult) {
                setNotFound(true);
            } else {
                setTender(tenderResult);
                setTitle(`Underleverandører søkes for: ${tenderResult.tittel}.`); // Set the initial title to tender.tittel
                setDescription(
                    `Bedrift leter etter sammarbeidspartner eller underleverandør for kunngjøring med doffinid ${doffinAnbudId}. Tilbudet skal dekke CPV koder: ${tenderResult.cpvKoder.map((k: AnbudSummaryCpvCode) => k.kode).join(', ')}. Utførelsessted: ${tenderResult.steder.map((s: AnbudSummaryPlace) => s.navn).join(', ')}.`
                ); // Set the initial description
            }
            setLoading(false);
        };

        if (doffinAnbudId) {
            fetchTenderAndCheckBulletin().catch(console.error);
        } else {
            setLoading(false);
        }
    }, [doffinAnbudId, getTender, checkBulletin]);

    const handleSubmit = async () => {
        const newBulletin: Partial<SupplierBulletinCreate> = {
            title,
            description,
            doffinAnbudId: doffinAnbudId,
        };

        const result = await createBulletin(newBulletin);
        if (result) {
            navigate(`${paths.portal}/${paths.supplierbulletinlist}/`);
        }
    };

    if (alreadyExists) {
        return (
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <Typography variant="h5" color="error">
                        Det finnes allerede en bulletin for denne kunngjøringen
                    </Typography>
                </Box>
            </Container>
        );
    }

    if (serverError) {
        return (
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <Typography variant="h5" color="error">
                        Noe gikk galt...
                    </Typography>
                </Box>
            </Container>
        );
    }

    if (notFound) {
        return (
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <Typography variant="h5" color="error">
                        Kunngjøring ikke funnet
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            {(loading || tenderLoading || createLoading || bulletinLoading) && (
                <Backdrop open={loading || tenderLoading || createLoading || bulletinLoading} style={{ zIndex: 1000, position: 'absolute', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
                    <CircularProgress sx={{ color: 'white' }} />
                    <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
                        Laster innhold...
                    </Typography>
                </Backdrop>
            )}
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4" gutterBottom>
                    Opprett bulletin for underleverandører
                </Typography>
                <TextField
                    label="Tittel"
                    variant="outlined"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ marginBottom: '1rem' }}
                />
                <TextField
                    label="Beskrivelse"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{ marginBottom: '1rem' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading || tenderLoading || createLoading || bulletinLoading}
                    sx={{ marginBottom: '2rem' }} // Add padding below the button
                >
                    Opprett bulletin
                </Button>
            </Box>
        </Container>
    );
};

export default CreateSupplierBulletin;
